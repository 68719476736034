import React from 'react';
import discord from '../images/discord.svg';
import twitter from '../images/twitter.svg';
import email from '../images/mail.svg';
import youtube from '../images/youtube.svg';

export default function Social() {
  return (
    <ul className="social-list">
      <li>
        <a href="https://twitter.com/pumabrowser">
          <img src={twitter} alt="Twitter" />
        </a>
      </li>
      <li>
        <a href="https://discord.gg/4frpm84">
          <img src={discord} alt="Discord" />
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCC4Pes7enjQF80zq67A4Wew">
          <img src={youtube} alt="Youtube" />
        </a>
      </li>
      <li>
        <a href="mailto:info@pumabrowser.com">
          <img src={email} alt="Email" />
        </a>
      </li>
    </ul>
  );
}
