import React from 'react';
import { Spring } from 'react-spring';

import SEO from '../seo';
import MainSection from '../../styles/MainSection';
import Social from '../social';

const Success = () => (
  <>
    <SEO title="Thank You" />
    <MainSection>
      <Spring from={{ opacity: 0, bottom: -40 }} to={{ opacity: 1, bottom: 0 }}>
        {props => (
          <div className="container" style={props}>
            <h1>Thanks for reaching out!</h1>
            <p>We'll get back to you ASAP</p>
            <Social />
          </div>
        )}
      </Spring>
    </MainSection>
  </>
);

export default Success;
